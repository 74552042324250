import './App.css';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import Instructions from './components/instructions';
import AnnotationForm from './components/annotation-form';
import theme from './theme';

const NEW_LINK_URL = `https://fugu-annotation.azurewebsites.net/api/getrandomlink?annotator=`;
const ALL_ANNOTATORS_URL = "https://fugu-annotation.azurewebsites.net/api/getannotators";
const TOTAL_LINKS = 242;

function App() {
  const [allAnnotators, setAllAnnotators] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [processing, setProcessing] = useState(true);
  const [fetching, setFetching] = useState(false);

  const [link, setLink] = useState({
    annotator: '',
    website_link: '',
    "closeup.png": '',
    "destination.png": '',
    "full.png": '',
    "data.json": ''
  });
  const [annotator, setAnnotator] = useState('');
  const [annotatorSubmitted, setAnnotatorSubmitted] = useState(false);

  async function getNewLinkForAnnotation() {
    setFetching(true);
    const response = await fetch(NEW_LINK_URL + annotator);
    const data = await response.json();
    data["data.json"] = JSON.parse(data["data.json"]);
    setLink(data);
    setFetching(false);
  }

  useEffect(() => {
    async function getAllAnnotators() {
      if (Object.keys(allAnnotators).length > 0) {
        setFetching(false);
        return;
      }
      const response = await fetch(ALL_ANNOTATORS_URL);
      const resolved = await response.json();
      setAllAnnotators(resolved);
      setFetching(false);
    }

    setFetching(true);
    getAllAnnotators();
  }, []);

  useEffect(() => {
    if (annotatorSubmitted && annotator !== '') {
      getNewLinkForAnnotation();
      setProcessing(false);
    } 
  }, [annotatorSubmitted]);

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
    return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted or the alert is shown again
  }, [showAlert]);

  // create modal component to enter annotator name with material ui
  const handleAnnotatorChange = (event, newValue) => {
    setAnnotator(newValue);
    if (annotator === '') {
      setAnnotatorSubmitted(false);
    }
  }

  const annotatorModal = (
    <div id="annotator-modal">
      <h2>Enter your name to begin annotating</h2>
      <Autocomplete
        options={Object.keys(allAnnotators)}
        id="annotator-input"
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Name"
            variant="outlined"
            onChange={(e) => setAnnotator(e.target.value)}
          />
        )}
        value={annotator}
        onChange={handleAnnotatorChange}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => { 
          if (annotator !== '') {
            setAnnotatorSubmitted(true)
            if (!allAnnotators[annotator])
              allAnnotators[annotator] = 0;
          }
        }}
      >
        Submit
      </Button>
    </div>
  );

  const submissionHandler = async (err, data) => {
    if (err) {
      setAlertData({ severity: 'error', message: 'Error submitting annotation. Please try again or let Sauvik know.' });
    } else {
      setAlertData({ severity: 'success', message: 'Annotation submitted successfully!' });
      allAnnotators[annotator] += 1;
      await getNewLinkForAnnotation();
    }
    setProcessing(false);
    setShowAlert(true);
  }


  // create alert for successful submission or error
  const alert = (
    <section id="alert">
      <Alert severity={alertData.severity}>
        { alertData.message }
      </Alert>
    </section>
  );

  // create circular progress with label of annotator count over total links
  const annotatorProgress = (
    <section id="progress">
      <p>{allAnnotators[annotator]} / {TOTAL_LINKS} links annotated</p>
    </section>
  );

  const loading = (
    <Box className="loading">
      <CircularProgress />
    </Box>
  );

  const splitPanel = (
    <section id="split-panel">
      <section id="scraped-info">
        <Instructions linkData={link} />
      </section>
      <section id="annotation-form">
        <AnnotationForm
          submissionHandler={submissionHandler}
          processingHandler={setProcessing}
          annotator={annotator}
          website_link={ link.website_link.split('/').slice(1).join('/') }
          pageURL={ link["data.json"].pageURL }
        />
      </section>
    </section>
  );

  return (
    <ThemeProvider theme={theme}>
      <main className="App">
        {annotator === '' || !annotatorSubmitted ? (
          fetching ? loading : annotatorModal
        ) : (
        <>
          <section id="main">
            <section id="annotator">
              <h2>Current annotator: {annotator}</h2>
              <Button
                variant="text"
                color="primary"
                onClick={() => setAnnotatorSubmitted(false)}
              >
                Change annotator
              </Button>
              {annotatorProgress}
            </section>
            { showAlert && alert }
            { (processing || fetching) ? loading : splitPanel }
          </section>
        </>
      )}
      </main>
    </ThemeProvider>
  );
}

export default App;