import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import html from "html";
import { CodeBlock, dracula } from 'react-code-blocks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function Instructions({ linkData }) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showLinkDOM, setShowLinkDOM] = useState(false);
  const [showStrippedDOM, setShowStrippedDOM] = useState(false);

  const handleOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="instructions">
      <Box className="instructionsSection">
        <Typography variant="h3">Instructions</Typography>
        <Typography variant="body1">
          Below, you will see a link and three images. Your task is to rate the {' '}
          <Tooltip title="Information scent refers to the extent to which users should be able to predict what they will find if they follow a certain link.">
            <span style={{ textDecoration: "underline", cursor: "help" }}>
              information scent
            </span>
          </Tooltip>
          {' '} of the link based on the images provided.
          One of the images will be a "close-up" shot of the link; another will be a "context" shot of the webpage where the link is located; and the third will be a "destination" shot of the webpage that loads when the link is clicked.
          A few things you should consider when rating the information scent of the link are:
          <ul>
            <li>
              <Typography variant="body1">The relevance of the link text to the page shown in the Destination image.</Typography>
            </li>
            <li>
              <Typography variant="body1">The positioning of the link in the page (e.g., is the link near other similar sorts of links or content that would point to its destination?)</Typography>
            </li>
            <li>
              <Typography variant="body1">The ease of understanding what the link leads to based on the images.</Typography>
            </li>
          </ul>
        </Typography>
      </Box>
      <Box className="instructionsSection">
        <Typography variant="h5">Metadata</Typography>
        <Typography variant="body1"><b>Page URL</b>: {linkData["data.json"].pageURL}</Typography>
        <Typography variant="body1"><b>Link Text</b>: {linkData["data.json"].linkText}</Typography>
      </Box>
      <Box className="instructionsSection">
        <Typography variant="h5">Close-up shot</Typography>
        <img
          // convert base64 to image for src
          src={`data:image/png;base64,${linkData["closeup.png"]}`}
          className="closeImage clickable"
          alt="Close-up view"
          onClick={() => handleOpen(`data:image/png;base64,${linkData["closeup.png"]}`)}
        />
        <Typography variant="p">This is a zoomed-in view of the link you are rating. The link should be enclosed in a red box.</Typography>
      </Box>
      <Box className="instructionsSection">
        <Typography variant="h5">Context shot</Typography>
        <img
          src={`data:image/png;base64,${linkData["full.png"]}`}
          className="fullImage clickable"
          alt="Full context view"
          onClick={() => handleOpen(`data:image/png;base64,${linkData["full.png"]}`)}
        />
        <Typography variant="p">This is the image of the webpage where the link should be visible. The link should be enclosed in a red box.</Typography>
      </Box>
      <Box className="instructionsSection">
        <Typography variant="h5">Destination shot</Typography>
        <img
          src={`data:image/png;base64,${linkData["destination.png"]}`}
          className="fullImage clickable"
          alt="Destination view"
          onClick={() => handleOpen(`data:image/png;base64,${linkData["destination.png"]}`)}
        />
        <Typography variant="p">This is the image of the webpage that loads when the link is clicked.</Typography>
      </Box>
      <Box className="instructionsSection">
        <Typography variant="h5">DOM</Typography>
        <Typography variant="h6" onClick={() => setShowLinkDOM(!showLinkDOM)} style={{ cursor: 'pointer' }}>
          Link DOM (click to {showLinkDOM ? "hide" : "reveal" }) {showLinkDOM ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Typography>
        {showLinkDOM && (
          <CodeBlock
            text={html.prettyPrint(linkData["data.json"].linkDOM, { indent_size: 2 })}
            language="html"
            id="linkDOM"
            theme={dracula}
            wrapLongLines="true"
          />
        )}
        <Typography variant="h6" onClick={() => setShowStrippedDOM(!showStrippedDOM)} style={{ cursor: 'pointer' }}>
          Stripped DOM (click to {showStrippedDOM ? "hide" : "reveal" }) {showStrippedDOM ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Typography>
        {showStrippedDOM && (
          <CodeBlock
            text={html.prettyPrint(linkData["data.json"].strippedDOM, { indent_size: 2 })}
            id="strippedDOM"
            language="html"
            theme={dracula}
            wrapLongLines="true"
          />
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box className="modalStyle">
          <Box id="modalCloseContainer">
            <IconButton 
              onClick={handleClose} 
              className="modalCloseButton"
              sx={{
                color: 'red',
                background: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="modalImageContainer">
            <img src={selectedImage} alt="Enlarged view" className="modalImage" />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Instructions;