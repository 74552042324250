import React, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Modal,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@mui/material';
import { TableClient } from "@azure/data-tables";
import { InteractiveBrowserCredential } from "@azure/identity";

function AnnotationForm({ 
  submissionHandler,
  processingHandler,
  annotator,
  website_link,
  pageURL
 }) {
  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const [scentRating, setScentRating] = useState('');
  const [explanation, setExplanation] = useState('');
  const [improvementSuggestions, setImprovementSuggestions] = useState('');
  const [expectation, setExpectation] = useState('');
  const [skipRationale, setSkipRationale] = useState('');

  const resetForm = () => {
    setScentRating('');
    setExplanation('');
    setImprovementSuggestions('');
    setExpectation('');
    setSkipRationale('');
  }

  const handleOpenSkipModal = () => {
    setSkipModalOpen(true);
  };

  const handleCloseSkipModal = () => {
    setSkipModalOpen(false);
  };

  const handleRatingChange = (event) => {
    setScentRating(event.target.value);
  };

  const handleExplanationChange = (event) => {
    setExplanation(event.target.value);
  };

  const handleImprovementSuggestionsChange = (event) => {
    setImprovementSuggestions(event.target.value);
  };

  const handleExpectationChange = (event) => {
    setExpectation(event.target.value);
  };

  const handleSkipRationaleChange = (event) => {
    setSkipRationale(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitToAzureTable(scentRating, explanation, improvementSuggestions, expectation, skipRationale);
    handleCloseSkipModal();
  };

  const skipModal = (
    <Modal
      open={skipModalOpen}
      onClose={handleCloseSkipModal}
      aria-labelledby="skip-modal-title"
      aria-describedby="skip-modal-description"
    >
      <Box
        className="modalBox"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400, // or whatever width you want
          bgcolor: 'background.paper', // theme-based white color
          boxShadow: 24,
          p: 4, // padding
          borderRadius: 2, // optional border radius
          outline: 'none' // removes the default focus outline
        }}
      >
        <Typography id="skip-modal-title" variant="h6" component="h2">
          Skip this Link
        </Typography>
        <TextField
          id="skip-reason"
          label="Reason for skipping"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={skipRationale}
          onChange={handleSkipRationaleChange}
        />
        <Button variant="contained" sx={{ pt: 1 }} onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Modal>
  );

  const submitToAzureTable = async (
    scentRating,
    explanation,
    improvementSuggestions,
    expectation,
    skipRationale,
  ) => {
    processingHandler(true);

    const AZURE_STORAGE_ACCOUNT_NAME = 'fuguuxaidatacrawls';
    const TABLE_NAME = 'infoscentannotations';

    const tableClient = new TableClient(
      `https://${AZURE_STORAGE_ACCOUNT_NAME}.table.core.windows.net`,
      TABLE_NAME,
      new InteractiveBrowserCredential({
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      })
    );

    // Parameters for DynamoDB
    const entity = {
      'partitionKey': annotator,
      'rowKey': website_link.replace(/\//g, "-"),
      'pageURL': pageURL,
      'ScentRating': scentRating,
      'Explanation': explanation,
      'ImprovementSuggestions': improvementSuggestions || 'N/A',
      'Expectation': expectation || 'N/A',
      'SkipRationale': skipRationale || 'N/A',
    };
  
    try {
      // Insert data into Azure Table Storage
      await tableClient.createEntity(entity);
      await submissionHandler(null, entity);
      resetForm();
    } catch (err) {
      console.error(err);
      await submissionHandler(err, null);
    }
  }

  const annotationForm = (
    <Box className="annotationForm">
      <Typography variant="h3">Annotation Form</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="scent-rating-label">Rate the information scent of this link</InputLabel>
          <Select
            labelId="scent-rating-label"
            id="scent-rating"
            value={scentRating}
            label="Rate the information scent of this link"
            onChange={handleRatingChange}
            required
          >
            <MenuItem value="Excellent">Excellent</MenuItem>
            <MenuItem value="Good">Good</MenuItem>
            <MenuItem value="Okay">Okay</MenuItem>
            <MenuItem value="Poor">Poor</MenuItem>
          </Select>
          <FormHelperText>
            Information scent refers to how well a link communicates where it will take you. Please refer to link text and screenshots provided. 
          </FormHelperText>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          id="explanation"
          label="Why did you rate the information scent as you did above?"
          multiline
          rows={2}
          variant="outlined"
          onChange={handleExplanationChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          id="improvement-suggestions"
          label="What could be done to improve the information scent of this link?"
          multiline
          rows={4}
          onChange={handleImprovementSuggestionsChange}
          variant="outlined"
        />
        <TextField
          fullWidth
          margin="normal"
          id="expectation"
          label="If you rated the information scent as 'Okay' or 'Poor', where would you expect this link to take you?"
          multiline
          rows={2}
          onChange={handleExpectationChange}
          variant="outlined"
        />
        <Button variant="contained" type="submit" sx={{ mt: 2 }}>Submit</Button>
        <Button variant="text" sx={{ mt: 2 }} onClick={handleOpenSkipModal}>Skip</Button>
      </form>
    </Box>
  )

  return (
    <Box className="annotationFormContainer">
      { skipModal }
      { annotationForm }
    </Box>
  );
}

export default AnnotationForm;